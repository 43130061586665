import React from 'react'

import Layout from '../components/layout'
// import { graphql, Link } from 'gatsby'
// import GatsbyImage, { FixedObject } from 'gatsby-image'

import { IndexQuery } from '../generated/graphql'

// import Footer from '../components/Footer'
// import Header from '../components/header'
// import PoliticaPrivacidade from '../components/PoliticaPrivacidade'
// import Termos from '../components/Termos'

interface IndexProps {
	data: IndexQuery
}

const URL = `${process.env.GATSBY_IFRAME_PROTOCOL}://${process.env.GATSBY_IFRAME_URL}`

const IndexPage: React.FC<IndexProps> = () => {
	return (
		<Layout>
			<iframe
				id='iframe-assinatura-digital'
				className='w-full h-screen'
				frameBorder='0'
				title='Iframe de Assinatura Digital'
				src={`${URL}`}
				allowFullScreen
			/>
		</Layout>
	)
}
export default IndexPage
